<template>
  <v-app id="inspire">
    <v-content class="bgc-login">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <div class="text-center">
              <div class="d-flex align-center teal--text justify-center">
                <div class="not-found-4">4</div>
                <v-icon size="100" class="spin mb-5" color="teal"> mdi-help-circle-outline </v-icon>
                <div class="not-found-4">4</div>
              </div>
              <h1>{{ $t('shared.pageNotFound') }}</h1>

              <v-btn to="/" class="fa mt-12" color="info">
                {{ $t('shared.returnHome') }}
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>

  <!-- <div>
        <v-icon size="150" color="teal" class="mb-6">
          mdi-access-point-network-off
        </v-icon>
        <h1 class="mb-10"> {{otherError}}
          <v-icon size="35" class="spin"> mdi-emoticon-sad-outline </v-icon>
        </h1>
        <div class="grey--text">
          <v-icon color="info"> mdi-alert-circle-outline </v-icon>
          {{tryAgain}}
        </div>
      </div> -->
</template>

<script>
export default {
  data() {
    return {
      pageNotFound: 'متاسفانه صفحه مورد نظر یافت نشد',
      otherError: 'خطا در ارتباط با شبکه',
      tryAgain: 'چند لحظه دیگر دوباره تلاش کنید'
    }
  }
}
</script>
<style lang="scss" scoped>
.not-found-4 {
  font-size: 7.5625rem;
  font-weight: bold;
}
</style>
